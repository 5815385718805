import React, { useContext } from "react";
import { GeneContext } from "../../../contexts/GeneContext";
import { GeneNumericInput } from "../common/NumericInput";
import GenePaneHeader from "../common/GenePaneHeader";
import GenePaneRow from "../common/GenePaneRow";

interface BpRowsProps {
  category: "bp2" | "bp5";
  fieldPostfix: string;
}

const BpRows = (props: BpRowsProps) => {
  const { category, fieldPostfix } = props;
  const { criteriaRankings, isCriteriaApplied, resetFieldsToDefaults } =
    useContext(GeneContext);

  const criteria = criteriaRankings[category].ranking;

  return (
    <>
      <GenePaneHeader
        headers={[
          "Criteria",
          `Minimum patients - alternate cause - ${fieldPostfix} gene`,
        ]}
        onResetDefaults={() => resetFieldsToDefaults(criteria)}
      />
      {criteria.map((c) => {
        const minPatients = `${c}_min_patients_alternate_cause_${fieldPostfix}`;
        const applied = isCriteriaApplied(c);

        return (
          <GenePaneRow
            key={`${c}-row`}
            criteria={c}
            applied={applied}
            required={[minPatients]}>
            <GeneNumericInput
              field={minPatients}
              applied={applied}
              required={true}
              min={1}
              step={1}
            />
          </GenePaneRow>
        );
      })}
    </>
  );
};

export default BpRows;
