import React, { useContext } from "react";
import { Col, Form } from "react-bootstrap";
import isNil from "lodash/isNil";
import { GeneContext } from "../../../contexts/GeneContext";
import { GeneNumericInput } from "../common/NumericInput";
import GenePaneHeader from "../common/GenePaneHeader";
import GenePaneRow from "../common/GenePaneRow";

const Pm5Rows = () => {
  const {
    changeSingleGeneValue,
    criteriaRankings,
    errorState,
    gene,
    germlineDefaults,
    isCriteriaApplied,
    resetFieldsToDefaults,
  } = useContext(GeneContext);

  const convertBooleanValue = (value: boolean | null) =>
    isNil(value) ? "" : value.toString();

  const criteria = criteriaRankings.pm5.ranking;

  return (
    <>
      <GenePaneHeader
        includeApply={true}
        headers={[
          "Criteria",
          "Number of P/LP missense variants",
          "Grantham score",
        ]}
        onResetDefaults={() => resetFieldsToDefaults(criteria)}
      />

      {criteriaRankings.pm5.ranking.map((c) => {
        const missenseVariants = `${c}_missense_variants`;
        const granthamScore = `${c}_grantham_score`;
        const applied = isCriteriaApplied(c);

        return (
          <GenePaneRow
            key={`${c}-row`}
            criteria={c}
            applied={applied}
            required={[missenseVariants, granthamScore]}>
            <GeneNumericInput
              applied={applied}
              field={missenseVariants}
              required={applied}
              min={1}
            />
            <Form.Group as={Col} sm={3} className="mr-2">
              <Form.Control
                aria-label={granthamScore}
                as="select"
                name={granthamScore}
                onChange={changeSingleGeneValue}
                value={
                  convertBooleanValue(gene[granthamScore]) ||
                  convertBooleanValue(germlineDefaults[granthamScore])
                }
                disabled={!applied}
                required={applied}
                className={errorState[granthamScore] ? "is-invalid" : ""}>
                <option disabled hidden value=""></option>
                <option value="true">True</option>
                <option value="false">False</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errorState[granthamScore]}
              </Form.Control.Feedback>
            </Form.Group>
          </GenePaneRow>
        );
      })}
    </>
  );
};

export default Pm5Rows;
