import React, { useContext } from "react";
import { BsPlusLg, BsXLg } from "react-icons/bs";
import { Button, Col, Form } from "react-bootstrap";
import { RangeOption } from "../../../lib/Gene";
import { GeneContext } from "../../../contexts/GeneContext";
import { Range } from "../../../lib/Gene";
import NumericInput from "../common/NumericInput";
import GenePaneRow from "../common/GenePaneRow";
import GenePaneHeader from "../common/GenePaneHeader";
import ModelSelect from "./ModelSelect";

const FIELD_NAME = "ranges";

const Ranges = () => {
  const {
    changeStructuredGeneValue,
    criteriaRankings,
    errorState,
    gene,
    modelOptions,
    isCriteriaApplied,
    resetFieldsToDefaults,
    updateRangeErrors,
  } = useContext(GeneContext);

  const spacingColumns = (
    <>
      <div className="w-100" />
      <Col className="apply-criteria" />
      <Col className="col-sm-2" />
    </>
  );

  const resolveRanges = () => {
    return gene.ranges ? gene.ranges : [];
  };

  const resolveRangeOptions = () => {
    return gene.range_options ? gene.range_options : [];
  };

  const accessRangeError = (index: number) => {
    if (errorState[FIELD_NAME] != null) {
      return errorState[FIELD_NAME][index];
    }

    return null;
  };

  const onResetDefaults = () => {
    resetFieldsToDefaults(criteriaRankings.ps3.ranking);
    changeStructuredGeneValue([], FIELD_NAME);
  };

  const onFunctionalRangeChange = (
    e: React.ChangeEvent<any>,
    index: number
  ) => {
    const { name, value } = e.target;
    const ranges = resolveRanges();

    if (name === "units/test") {
      const option = gene.range_options?.find(
        (o) => `${o.units} ${o.test}` === value
      );

      if (option && !ranges[index].range_option) {
        ranges[index].range_option = option;
      } else if (option) {
        ranges[index].range_option.units = option.units;
        ranges[index].range_option.test = option.test;
      }
    } else {
      const newValue = value !== "" && value !== null ? Number(value) : null;
      ranges[index][name] = newValue;
    }

    changeStructuredGeneValue(ranges, FIELD_NAME);
  };

  const onAddRange = (criteria: string) => {
    const option = resolveRangeOptions().find(Boolean);
    const ranges = resolveRanges();
    ranges.push({
      start: null,
      stop: null,
      criteria,
      range_option: {
        units: option ? option.units : "",
        test: option ? option.test : "",
      },
    });
    changeStructuredGeneValue(ranges, FIELD_NAME);
  };

  const removeRangeErrorFromErrors = (index: number) => {
    const errors: Record<number, string> = {};

    for (const [key, value] of Object.entries(errorState[FIELD_NAME])) {
      const k = parseInt(key);

      if (!Number.isNaN(k) && k > index) {
        errors[k - 1] = `${value}`;
      }

      if (!Number.isNaN(k) && k < index) {
        errors[k] = `${value}`;
      }
    }

    return errors;
  };

  const onRemoveRange = (index: number) => {
    const ranges = resolveRanges();
    ranges.splice(index, 1);
    changeStructuredGeneValue(ranges, FIELD_NAME);

    if (!!accessRangeError(index)) {
      updateRangeErrors(removeRangeErrorFromErrors(index));
    }
  };

  const addRangeButtonRow = (indices: number[], criteria: string) => {
    const criteriaApplied = isCriteriaApplied(criteria);
    const button = (
      <Button
        variant="outline-primary"
        disabled={!(criteriaApplied && resolveRangeOptions().length)}
        onClick={() => onAddRange(criteria)}>
        <BsPlusLg />
        Add range
      </Button>
    );

    if (indices.length) {
      return (
        <>
          {spacingColumns}
          <Form.Group as={Col}>{button}</Form.Group>
        </>
      );
    }

    return button;
  };

  const updateRange = (range: Range, index: number) => {
    const ranges = gene.ranges ? [...gene.ranges!] : [];
    ranges[index] = { ...range };
    changeStructuredGeneValue(ranges, "ranges");
  };

  const renderRangeRows = () => {
    const criteria = criteriaRankings.ps3.ranking;

    return criteria.map((c) => {
      const criteriaApplied = isCriteriaApplied(c);
      const ranges = resolveRanges();

      let indices = ranges.reduce(
        (acc: number[], range: Range, index: number) => {
          if (range["criteria"] === c) acc.push(index);
          return acc;
        },
        []
      );

      return (
        <React.Fragment key={c}>
          <GenePaneRow criteria={c} applied={criteriaApplied} required={[]}>
            <>
              {indices.map((index: number, i: number) => {
                const range = ranges[index];
                const startMax = range["stop"]
                  ? { max: Number(range["stop"]) - 1 }
                  : {};
                const error = accessRangeError(index);
                return (
                  <React.Fragment key={index}>
                    {i != 0 && spacingColumns}
                    <Form.Group
                      as={Col}
                      sm={2}
                      id="range-start-stop"
                      className="mr-2">
                      <NumericInput
                        allowDecimals={true}
                        applied={criteriaApplied}
                        asFormGroup={false}
                        field="start"
                        onChange={(e) => onFunctionalRangeChange(e, index)}
                        min={0}
                        size={1}
                        required={criteriaApplied}
                        value={range["start"]}
                        {...startMax}
                      />
                      <NumericInput
                        allowDecimals={true}
                        applied={criteriaApplied}
                        asFormGroup={false}
                        field="stop"
                        onChange={(e) => onFunctionalRangeChange(e, index)}
                        min={range["start"] ? Number(range["start"]) + 1 : 0}
                        size={1}
                        required={criteriaApplied}
                        value={range["stop"]}
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm={2} className="range-fields">
                      <Form.Control
                        as="select"
                        name="units/test"
                        onChange={(e) => onFunctionalRangeChange(e, index)}
                        value={
                          range && range.range_option
                            ? `${range.range_option.units} ${range.range_option.test}`
                            : ""
                        }
                        disabled={!criteriaApplied}
                        required={criteriaApplied}>
                        {resolveRangeOptions().map((ro: RangeOption) => {
                          return (
                            <option
                              key={`${ro.units}-${ro.test}`}
                              value={`${ro.units} ${ro.test}`}>{`${ro.units} ${ro.test}`}</option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>
                    <ModelSelect
                      index={index}
                      options={modelOptions}
                      range={range}
                      updateRange={updateRange}
                    />
                    <Form.Group as={Col} sm={1}>
                      <Button
                        aria-label="remove"
                        data-testid={`remove-range-${index}`}
                        className="ml-4"
                        disabled={!criteriaApplied}
                        variant="transparent"
                        onClick={() => onRemoveRange(index)}>
                        <BsXLg />
                      </Button>
                    </Form.Group>
                    {error && (
                      <>
                        {spacingColumns}
                        <Form.Group as={Col} sm={6} className="mb-3">
                          <Form.Control.Feedback
                            type="invalid"
                            data-testid={`range-error-${index}`}>
                            {error}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </>
                    )}
                  </React.Fragment>
                );
              })}
            </>
            {addRangeButtonRow(indices, c)}
          </GenePaneRow>
        </React.Fragment>
      );
    });
  };

  return (
    <>
      <GenePaneHeader
        headers={[
          "Criteria",
          "Range",
          "Units/Test",
          "Models (optional)",
          "Cell Types (optional)",
        ]}
        onResetDefaults={onResetDefaults}
        size={2}
      />
      {renderRangeRows()}
    </>
  );
};

export default Ranges;
