import React, { useContext } from "react";
import { BsPlusLg } from "react-icons/bs";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Domain } from "../../../lib/Gene";
import { GeneContext } from "../../../contexts/GeneContext";
import GenePaneHeader from "../common/GenePaneHeader";
import NumericInput from "../common/NumericInput";

const Domains = () => {
  const {
    changeDomains,
    domains,
    domainDefaults,
    domainOptions,
    errorState,
    resetFieldsToDefaults,
  } = useContext(GeneContext);

  const onDomainAdd = () => {
    domains.push({
      name: null,
      start: null,
      end: null,
      ignore: true,
      application: null,
      strength: null,
      source: "manual",
    });
    changeDomains(domains);
  };

  const onDomainChange = (e: React.ChangeEvent<any>, index: number) => {
    const name = e.target.name;
    let value = name === "ignore" ? !e.target.checked : e.target.value;
    domains[index][name] = value;
    changeDomains(domains);
  };

  const renderDomainFeature = (domain: Domain, index: number) => {
    if (domain.source === "uniprot") {
      return <Col className="col-sm-2 mr-2">{domain.name}</Col>;
    }

    return (
      <Form.Group as={Col} sm={2} className="mr-2">
        <Form.Control
          aria-label="name"
          type="text"
          name="name"
          onChange={(e) => onDomainChange(e, index)}
          value={domain.name || ""}
        />
      </Form.Group>
    );
  };

  const renderDomainStart = (domain: Domain, index: number) => {
    if (domain.source === "uniprot") {
      return <Col className="col-sm-1 mr-2">{domain.start}</Col>;
    }

    return (
      <NumericInput
        field="start"
        onChange={(e) => onDomainChange(e, index)}
        min={1}
        size={1}
        value={domain.start}
      />
    );
  };

  const renderDomainStop = (domain: Domain, index: number) => {
    if (domain.source === "uniprot") {
      return <Col className="col-sm-1 mr-2">{domain.end}</Col>;
    }

    return (
      <NumericInput
        field="end"
        onChange={(e) => onDomainChange(e, index)}
        min={1}
        size={1}
        value={domain.end}
      />
    );
  };

  const renderDomainSelect = (domain: Domain, index: number, field: string) => {
    return (
      <Form.Group
        as={Col}
        sm={2}
        className={`${
          domain.source === "uniprot" ? "mr-2" : "domain-dropdown"
        }`}>
        <Form.Control
          aria-label={field}
          as="select"
          name={field}
          onChange={(e) => onDomainChange(e, index)}
          value={domain[field] || domainDefaults[field]}>
          {domainOptions[field].map((s) => {
            return (
              <option key={s.value} value={s.value}>
                {s.description}
              </option>
            );
          })}
        </Form.Control>
      </Form.Group>
    );
  };

  return (
    <>
      <GenePaneHeader
        includeApply={false}
        headers={["Domains and regions"]}
        onResetDefaults={() => resetFieldsToDefaults(["domains"])}
      />

      <Row>
        <Col className="font-weight-bold col-sm-2 mr-2">Feature</Col>
        <Col className="font-weight-bold col-sm-1 mr-2">Start</Col>
        <Col className="font-weight-bold col-sm-1 mr-2">Stop</Col>
        <Col className="font-weight-bold col-sm-2 mr-2">Application</Col>
        <Col className="font-weight-bold col-sm-2 mr-2">Strength</Col>
        <Col className="font-weight-bold col-sm-1 mr-2">Include?</Col>
      </Row>

      {domains.map((d: Domain, i: number) => {
        return (
          <Row key={`domain-${i}`}>
            {renderDomainFeature(d, i)}
            {renderDomainStart(d, i)}
            {renderDomainStop(d, i)}
            {renderDomainSelect(d, i, "application")}
            {renderDomainSelect(d, i, "strength")}
            <Form.Group as={Col} sm={1} className="mr-2">
              <Form.Check
                aria-label="include"
                className="pane-check"
                name="ignore"
                type="checkbox"
                checked={!d.ignore}
                onChange={(e) => onDomainChange(e, i)}
              />
            </Form.Group>
          </Row>
        );
      })}

      <Row>
        <Button variant="outline-primary" onClick={onDomainAdd}>
          <BsPlusLg />
          Add new region
        </Button>
        {errorState["gene_features"] && (
          <Form.Control.Feedback type="invalid">
            Domains failed to save.
          </Form.Control.Feedback>
        )}
      </Row>
    </>
  );
};

export default Domains;
