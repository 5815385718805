import React, { useContext } from "react";
import { GeneContext } from "../../../contexts/GeneContext";
import { GeneNumericInput } from "../common/NumericInput";
import GenePaneHeader from "../common/GenePaneHeader";
import GenePaneRow from "../common/GenePaneRow";

const Pm3Rows = () => {
  const { criteriaRankings, isCriteriaApplied, resetFieldsToDefaults } =
    useContext(GeneContext);

  const criteria = criteriaRankings.pm3.ranking;

  return (
    <>
      <GenePaneHeader
        headers={["Criteria", "Minimum probands"]}
        onResetDefaults={() => resetFieldsToDefaults(criteria)}
      />

      {criteria.map((c) => {
        const minProbands = `${c}_min_probands`;
        const applied = isCriteriaApplied(c);

        return (
          <GenePaneRow
            key={`${c}-row`}
            criteria={c}
            applied={applied}
            required={[minProbands]}>
            <GeneNumericInput
              field={minProbands}
              applied={applied}
              required={true}
              min={1}
              step={1}
            />
          </GenePaneRow>
        );
      })}
    </>
  );
};

export default Pm3Rows;
