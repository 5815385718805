import React from "react";
import { Button, Row, Col } from "react-bootstrap";

interface GenePaneHeaderProps {
  includeApply: boolean;
  headers: string[];
  onResetDefaults: () => void;
  size: number;
}

const defaultProps = {
  includeApply: true,
  headers: [],
  size: 3,
};

const GenePaneHeader = (props: GenePaneHeaderProps) => {
  const { includeApply, headers, onResetDefaults, size } = props;

  return (
    <>
      <Row className="align-items-center">
        {includeApply && (
          <Col className="font-weight-bold apply-criteria">Apply?</Col>
        )}
        {headers.map((header, index) => {
          const colSize = index === 0 ? 2 : size;
          return (
            <Col key={header} className={`font-weight-bold col-sm-${colSize}`}>
              {header}
            </Col>
          );
        })}
        <Col className="col-sm-1 ml-auto">
          <Button variant="outline-primary" onClick={onResetDefaults}>
            Reset defaults
          </Button>
        </Col>
      </Row>
    </>
  );
};

GenePaneHeader.defaultProps = defaultProps;

export default GenePaneHeader;
