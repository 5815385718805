import React, { useContext } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { GeneContext } from "../../../contexts/GeneContext";
import { GeneNumericInput } from "../common/NumericInput";
import GenePaneHeader from "../common/GenePaneHeader";
import GenePaneRow from "../common/GenePaneRow";
import { createSelectClassNames } from "../../../lib/Gene";

interface Selection {
  type: string;
  description: string;
}

const Ps2Rows = () => {
  const {
    categoryOptions,
    changeStructuredGeneValue,
    criteriaRankings,
    errorState,
    germlineDefaults,
    isCriteriaApplied,
    resetFieldsToDefaults,
    gene,
  } = useContext(GeneContext);

  const options = Object.entries(categoryOptions.de_novo.de_novo_types).map(
    ([key, value]) => ({ type: key, description: value.description })
  );

  const isUsingDefaultCategories = (criteria: string) =>
    !gene[`${criteria}_categories`];

  const resolveCategories = (criteria: string) => {
    const lookup = `${criteria}_categories`;
    if (gene[lookup] !== null) return [...gene[lookup]];
    return [...germlineDefaults[lookup]];
  };

  const filterAvailableOptions = (criteria: string) => {
    const criteriaToExclude = criteria === "ps2" ? "pm6" : "ps2";
    const exclude = resolveCategories(criteriaToExclude);
    return options.filter((o) => !exclude.includes(o.type));
  };

  const selectedValues = (criteria: string) => {
    const include = resolveCategories(criteria);
    return options.filter((o) => include.includes(o.type));
  };

  const onCategorySelect = (criteria: string, selected: Selection[]) => {
    const categories = selected.length ? selected.map((s) => s.type) : null;
    changeStructuredGeneValue(categories, `${criteria}_categories`);
  };

  const renderCategoryRows = () => {
    const criteria = ["ps2", "pm6"];

    return criteria.map((c, i) => {
      return (
        <Row key={`${c}-categories`}>
          <Col className="apply-criteria" />
          <Col className="font-weight-bold col-sm-2">{c.toUpperCase()}</Col>
          <Form.Group as={Col} sm={6} className="category-select">
            <Select
              classNames={createSelectClassNames(isUsingDefaultCategories(c))}
              value={selectedValues(c)}
              onChange={(selected: Selection[]) =>
                onCategorySelect(c, selected)
              }
              options={filterAvailableOptions(c)}
              getOptionLabel={(option) => option.description}
              getOptionValue={(option) => option.type}
              placeholder="Select categories"
              closeMenuOnSelect
              isClearable={false}
              isMulti
            />
            <Form.Control.Feedback type="invalid">
              {errorState[`${c}_categories`]}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
      );
    });
  };

  const renderDeNovoRows = () => {
    const criteria = [
      ...criteriaRankings.ps2.ranking,
      ...criteriaRankings.pm6.ranking,
    ];

    return criteria.map((c) => {
      const deNovo = `${c}_min_de_novo_patients`;
      const maf = `${c}_maf`;
      const applied = isCriteriaApplied(c);

      return (
        <GenePaneRow
          key={`${c}-row`}
          criteria={c}
          applied={applied}
          required={[deNovo]}>
          <GeneNumericInput
            field={deNovo}
            applied={applied}
            required={true}
            min={1}
            step={1}
          />
          <GeneNumericInput
            allowDecimals={true}
            field={maf}
            applied={applied}
            min={0}
            max={1}
            step={0.0001}
          />
        </GenePaneRow>
      );
    });
  };

  return (
    <>
      <GenePaneHeader
        headers={["Criteria", "Minimum de novo patients", "MAF (optional)"]}
        onResetDefaults={() => {
          resetFieldsToDefaults([
            ...criteriaRankings.ps2.ranking,
            ...criteriaRankings.pm6.ranking,
          ]);
        }}
      />

      {renderDeNovoRows()}

      <Row className="align-items-center">
        <Col className="apply-criteria" />
        <Col className="col-sm-2" />
        <Col className="font-weight-bold col-sm-2">Categories to include</Col>
      </Row>

      {renderCategoryRows()}
    </>
  );
};

export default Ps2Rows;
