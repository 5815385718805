import React from "react";
import Bs4Rows from "./clinical/Bs4Rows";
import Pp1Rows from "./clinical/Pp1Rows";
import Ps4Rows from "./clinical/Ps4Rows";
import Ps2Rows from "./clinical/Ps2Rows";
import Pm3Rows from "./clinical/Pm3Rows";
import Bs2Rows from "./clinical/Bs2Rows";
import BpRows from "./clinical/BpRows";

const ClinicalPane = () => {
  const components = [
    <Ps4Rows />,
    <Pp1Rows />,
    <Bs4Rows />,
    <Ps2Rows />,
    <Pm3Rows />,
    <Bs2Rows />,
    <BpRows category="bp2" fieldPostfix="same" />,
    <BpRows category="bp5" fieldPostfix="diff" />,
  ];

  return (
    <>
      {components.map((component, index) => (
        <div
          className={`criteria-section ${
            index == 0 ? "first-criteria-section" : ""
          }`}>
          {component}
        </div>
      ))}
    </>
  );
};

export default ClinicalPane;
