import * as React from "react";
import {
  ArticleCounts,
  CriteriaRankings,
  Domain,
  DomainOptions,
  Gene,
  GermlineDefaults,
  Pvs1Categories,
  Range,
  RangeOption,
  StrengthOptions,
} from "../lib/Gene";
import { CategoryOptions } from "../lib/CategoryOptions";
import { ModelOptions } from "../lib/ModelOptions";
import { ErrorState } from "../components/GenePanes";

export type GeneContextType = {
  articleCounts: ArticleCounts | null;
  categoryOptions: CategoryOptions;
  criteriaRankings: CriteriaRankings;
  domains: Domain[];
  domainDefaults: GermlineDefaults;
  domainOptions: DomainOptions;
  errorState: ErrorState;
  gene: Gene;
  germlineDefaults: GermlineDefaults;
  modelOptions: ModelOptions;
  strengthOptions: StrengthOptions;
  changeStructuredGeneValue: (
    values: string[] | Range[] | RangeOption[] | Pvs1Categories | null,
    field: string
  ) => void;
  changeDomains: (domains: Domain[]) => void;
  changeSingleGeneValue: (e: React.ChangeEvent<any>) => void;
  isCriteriaApplied: (criteria: string) => boolean;
  resetFieldsToDefaults: (fields: string[], message?: string) => void;
  toggleAppliedState: (
    criteria: string,
    value: boolean,
    required: string[]
  ) => void;
  updateRangeErrors: (error: Record<number, string>) => void;
};

export const GeneContextData: GeneContextType = {
  articleCounts: null,
  categoryOptions: {
    de_novo: {
      de_novo_types: {},
    },
    splicing: {
      impact_degrees: {},
      impact_types: {},
    },
  },
  criteriaRankings: {
    ba1: { ranking: [], fields: [] },
    ps4: { ranking: [], fields: [] },
    ps4_truncating: { ranking: [], fields: [] },
    pp1: { ranking: [], fields: [] },
    bs4: { ranking: [], fields: [] },
    ps2: { ranking: [], fields: [] },
    pm6: { ranking: [], fields: [] },
    pm3: { ranking: [], fields: [] },
    bs2: { ranking: [], fields: [] },
    bp2: { ranking: [], fields: [] },
    bp5: { ranking: [], fields: [] },
    pm5: { ranking: [], fields: [] },
    ps3: { ranking: [], fields: [] },
  },
  domains: [],
  domainDefaults: {},
  domainOptions: {
    application: [],
    strength: [],
  },
  errorState: {},
  gene: {
    categories: [],
    pvs1_categories: {
      "": [],
      strong: [],
      moderate: [],
      supporting: [],
    },
    ranges: [],
    range_options: [],
  },
  germlineDefaults: {},
  modelOptions: {},
  strengthOptions: {
    pp2_strength: {
      description: "",
      options: [],
    },
    bp1_strength: {
      description: "",
      options: [],
    },
    bp7_strength: {
      description: "",
      options: [],
    },
    hotspot_strength: {
      description: "",
      options: [],
    },
  },
  changeStructuredGeneValue: () => {},
  changeDomains: () => {},
  changeSingleGeneValue: () => {},
  isCriteriaApplied: () => false,
  updateRangeErrors: () => {},
  resetFieldsToDefaults: () => {},
  toggleAppliedState: () => {},
};

export const GeneContext =
  React.createContext<GeneContextType>(GeneContextData);
