import React, { useState } from "react";
import mount from "../mount";
import { Alert, Tab, Tabs, Button } from "react-bootstrap";
import {
  BsFillCheckCircleFill,
  BsFillExclamationTriangleFill,
} from "react-icons/bs";
import { ArticleCounts, Domain, Gene, StaticData } from "../lib/Gene";
import ClinicalPane from "./gene_panes/ClinicalPane";
import FunctionalPane from "./gene_panes/FunctionalPane";
import IntrinsicPane from "./gene_panes/IntrinsicPane";
import MainPane from "./gene_panes/MainPane";
import PopulationPane from "./gene_panes/PopulationPane";
import { GeneUpdateStatus, updateGene } from "../lib/Riphub";
import { GeneContext, GeneContextType } from "../contexts/GeneContext";
import GeneFormValidator from "../lib/GeneFormValidator";
import { isCriteriaApplied } from "../lib/Germline";
import {
  nullOutDomainFields,
  nullOutErrorsForCriteria,
  resetGeneForCriteria,
} from "../lib/GermlineDefaultReset";

const CONFIRM = "Reset germline defaults for criteria?";

enum Pane {
  clinical = "clinical",
  functional = "functional",
  intrinsic = "intrinsic",
  main = "main",
  population = "population",
}

interface GenePaneProps {
  gene: Gene;
  article_counts: ArticleCounts | null;
  domains: Domain[];
  static_data: StaticData;
}

export interface ErrorState {
  [key: string]: any | null;
}

const initialErrorState = (gene: Gene): ErrorState => {
  return Object.keys(gene).reduce((memo: ErrorState, key: string) => {
    memo[key] = null;
    return memo;
  }, {});
};

const GenePanes = (props: GenePaneProps) => {
  const [updateDisabled, setUpdateDisabled] = useState(false);
  const [geneSaved, setGeneSaved] = useState(false);
  const [geneErrored, setGeneErrored] = useState(false);

  const [contextData, setContextData] = useState<GeneContextType>({
    articleCounts: props.article_counts,
    categoryOptions: props.static_data.category_options,
    criteriaRankings: props.static_data.criteria_rankings,
    domains: props.domains,
    domainDefaults: props.static_data.domain_defaults,
    domainOptions: props.static_data.domain_options,
    errorState: initialErrorState(props.gene),
    gene: props.gene,
    germlineDefaults: props.static_data.germline_defaults,
    modelOptions: props.static_data.model_options,
    strengthOptions: props.static_data.strength_options,
    changeStructuredGeneValue: (values, field) => {
      const newCtx = { ...contextData };
      newCtx.gene[field] = values;
      setContextData((prevState) => {
        newCtx.errorState = { ...prevState.errorState };
        return { ...newCtx };
      });
    },
    changeDomains: (domains) => {
      const newCtx = { ...contextData };
      newCtx.domains = domains;
      setContextData({ ...newCtx });
    },
    changeSingleGeneValue: (e) => {
      const { name } = e.target;
      const value =
        e.target.type === "checkbox" ? e.target.checked : e.target.value;
      let newValue = convertValue(name, value);

      const errors = validateFormField(newValue, e);

      const newCtx = { ...contextData };
      newCtx.gene[name] = newValue;
      setContextData((prevState) => {
        newCtx.errorState = { ...prevState.errorState, ...errors };
        return { ...newCtx };
      });
    },
    isCriteriaApplied: (criteria: string): boolean => {
      return isCriteriaApplied(
        criteria,
        contextData.gene,
        contextData.germlineDefaults
      );
    },
    resetFieldsToDefaults: (fields, message) => {
      if (confirm(`${CONFIRM}${!!message ? "\n\n" + message : ""}`)) {
        resetGermlineDefaults(fields);
      }
    },
    toggleAppliedState: (criteria, value, required) => {
      let newCtx = { ...contextData };
      newCtx.gene[`apply_${criteria}`] = value;

      newCtx = enforceDropdownValueChangeOnApply(criteria, value, newCtx);

      const errors = GeneFormValidator.validateToggledApply(
        criteria,
        value,
        required,
        newCtx
      );

      setContextData((prevState) => {
        newCtx.errorState = { ...prevState.errorState, ...errors };
        return { ...newCtx };
      });
    },
    updateRangeErrors: (errors: Record<number, string>) => {
      setContextData((prevState) => {
        const newCtx = { ...contextData };
        newCtx.errorState = { ...prevState.errorState, ranges: { ...errors } };
        return { ...newCtx };
      });
    },
  });

  const geneLink = `/genes/${contextData.gene.symbol}`;

  const convertValue = (name: string, value: any) => {
    // Strength may be "" (normal designation)
    const notStrength = !name.endsWith("strength");
    if (value === "" && notStrength) return null;
    if (value === "true") return true;
    if (value === "false") return false;
    return value;
  };

  const validateFormField = (
    newValue: any,
    e: React.ChangeEvent<any>
  ): object => {
    e.target.value = newValue;
    let newCtx = { ...contextData };
    return GeneFormValidator.validateFormEvent(e, newCtx);
  };

  const enforceDropdownValueChangeOnApply = (
    criteria: string,
    apply: boolean,
    context: GeneContextType
  ) => {
    if (!apply) return context;

    if (criteria.includes("bs2")) {
      const field = `${criteria}_require_confirmation_of_unaffected_status`;
      if (
        context.gene[field] === null &&
        !(field in contextData.germlineDefaults)
      ) {
        context.gene[field] = true;
      }
    }

    if (criteria.includes("pm5")) {
      const field = `${criteria}_grantham_score`;
      if (
        context.gene[field] === null &&
        !(field in contextData.germlineDefaults)
      ) {
        context.gene[field] = false;
      }
    }

    return context;
  };

  const resetGermlineDefaults = (criteria: string[] = []) => {
    setContextData((prevState) => {
      let newCtx = { ...prevState };

      newCtx.domains = [...nullOutDomainFields(criteria, newCtx.domains)];
      const gene = resetGeneForCriteria(criteria, newCtx.gene);
      const errors = nullOutErrorsForCriteria(criteria, newCtx.gene);

      for (const [key, value] of Object.entries(gene)) {
        newCtx.gene[key] = value;
      }

      for (const [key, value] of Object.entries(errors)) {
        newCtx.errorState[key] = value;
      }

      return newCtx;
    });
  };

  //Creates error object for JSON schema fields
  const createErrorObject = (value: string[]) => {
    const errors: Record<number, string> = {};
    value.forEach((v) => {
      const match = v.match(/#\/(\d+)/);
      if (match && match.length >= 2) {
        const key = parseInt(match[1]);
        if (!Number.isNaN(key)) {
          const currentError = errors[parseInt(match[1])];
          errors[parseInt(match[1])] = currentError
            ? `${currentError} ${v}.`
            : `${v}.`;
        }
      }
    });

    return errors;
  };

  const handleErrorState = (response: GeneUpdateStatus) => {
    if (response.errors) {
      const errors: ErrorState = {};
      const objectErrors = ["ranges"];

      for (const [key, value] of Object.entries(response.errors)) {
        if (objectErrors.includes(key)) {
          errors[key] = { ...createErrorObject(value) };
        } else {
          const messages = value.map((v) => {
            v = v[v.length - 1] === "." ? v : `${v}.`;
            return v.charAt(0).toUpperCase() + v.slice(1);
          });
          errors[key] = messages.join(" ");
        }
      }

      const newCtx = { ...contextData };
      newCtx.errorState = { ...newCtx.errorState, ...errors };
      setContextData({ ...newCtx });
    }
  };

  const isUnchangedUniprotDomain = (domain: Domain) =>
    domain.source === "uniprot" &&
    !domain.id &&
    !domain.ignore &&
    domain.application === null &&
    domain.strength === null;

  const filterDomainsToSave = () =>
    [...contextData.domains].filter(
      (domain) => !isUnchangedUniprotDomain(domain)
    );

  const handleSubmit = async () => {
    setUpdateDisabled(true);
    setGeneErrored(false);
    setGeneSaved(false);

    const domains = filterDomainsToSave();

    await updateGene(contextData.gene, domains)
      .then((r) => {
        if (r.ok) {
          const newCtx = { ...contextData };
          newCtx.domains = r.features || [];
          newCtx.errorState = {};

          for (const [key, value] of Object.entries(r.gene!)) {
            newCtx.gene[key] = value;
          }

          setContextData({ ...newCtx });
          setGeneSaved(true);
          setTimeout(() => setGeneSaved(false), 3000);
        } else {
          handleErrorState(r);
          setGeneErrored(true);
          setTimeout(() => setGeneErrored(false), 3000);
        }
      })
      .catch((_) => {
        setGeneErrored(true);
        setTimeout(() => setGeneErrored(false), 3000);
      })
      .finally(() => {
        setUpdateDisabled(false);
      });
  };

  return (
    <div className="mb-4">
      <h2>
        Gene: <a href={geneLink}>{contextData.gene.symbol}</a>
      </h2>
      <div className="tab-wrapper">
        <GeneContext.Provider value={contextData}>
          <Tabs id="gene-threshold-tabs" defaultActiveKey={Pane.main}>
            <Tab eventKey={Pane.main} title="Main">
              <MainPane />
            </Tab>
            <Tab eventKey={Pane.clinical} title="Clinical">
              <ClinicalPane />
            </Tab>
            <Tab eventKey={Pane.population} title="Population">
              <PopulationPane />
            </Tab>
            <Tab eventKey={Pane.intrinsic} title="Intrinsic">
              <IntrinsicPane />
            </Tab>
            <Tab eventKey={Pane.functional} title="Functional">
              <FunctionalPane />
            </Tab>
          </Tabs>
        </GeneContext.Provider>
        <Button onClick={handleSubmit} disabled={updateDisabled}>
          Update Gene
        </Button>
        {geneSaved && (
          <Alert variant="success">
            <BsFillCheckCircleFill /> Gene successfully saved.
          </Alert>
        )}
        {geneErrored && (
          <Alert variant="danger">
            <BsFillExclamationTriangleFill /> Gene failed to save.
          </Alert>
        )}
      </div>
    </div>
  );
};

mount({
  GenePanes,
});

export default GenePanes;
