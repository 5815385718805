import React, { useContext, useState } from "react";
import { BsPlusLg, BsXLg } from "react-icons/bs";
import { Button, Col, Form, Row } from "react-bootstrap";
import { RangeOption } from "../../../lib/Gene";
import { GeneUpdateStatus, updateGene } from "../../../lib/Riphub";
import { GeneContext } from "../../../contexts/GeneContext";

const RangeOptions = () => {
  const { gene, changeStructuredGeneValue } = useContext(GeneContext);
  const [unsavedOption, setUnsavedOption] = useState<RangeOption | null>(null);
  const [errors, setErrors] = useState<JSX.Element[]>([]);
  const [saveDisabled, setSaveDisabled] = useState(false);

  const onAddUnsaved = () => {
    setUnsavedOption({ units: "", test: "" });
  };

  const onUnsavedChange = (e: React.ChangeEvent<any>) => {
    if (!unsavedOption) return;
    const { name, value } = e.target;
    setUnsavedOption({ ...unsavedOption, [name]: value });
  };

  const extractErrors = (response: GeneUpdateStatus) => {
    if (!response.errors) return [];
    const errors = response.errors["range_options"] || [];
    return errors.map((e) => <div key={e}>{e}</div>);
  };

  const resolveRangeOptions = () => {
    return gene.range_options ? gene.range_options : [];
  };

  const onSaveOption = async () => {
    setSaveDisabled(true);
    const options = [...resolveRangeOptions()];
    if (unsavedOption)
      options.push({
        units: unsavedOption.units.trim(),
        test: unsavedOption.test.trim(),
      });

    const record = {
      symbol: gene.symbol,
      range_options: options,
    };

    await updateGene(record)
      .then((r) => {
        if (r.ok) {
          changeStructuredGeneValue(options, "range_options");
          setErrors([]);
          setUnsavedOption(null);
        } else {
          setErrors(extractErrors(r));
        }
      })
      .catch((_) => {
        setErrors([<div>An unexpected error occurred.</div>]);
      })
      .finally(() => {
        setSaveDisabled(false);
      });
  };

  return (
    <div className="first-criteria-section">
      <Row>
        <Col className="font-weight-bold col-sm-2 mr-2">Units</Col>
        <Col className="font-weight-bold col-sm-2 mr-2">Test</Col>
      </Row>
      {resolveRangeOptions().map((option: RangeOption, index: number) => {
        return (
          <Row key={index}>
            <Col className="col-sm-2 mr-2">{option.units}</Col>
            <Col className="col-sm-2 mr-2">{option.test}</Col>
          </Row>
        );
      })}
      {unsavedOption && (
        <Row>
          <Form.Group as={Col} sm={2} className="mr-2">
            <Form.Control
              aria-label="units"
              type="text"
              name="units"
              onChange={(e) => onUnsavedChange(e)}
              value={unsavedOption.units || ""}
              required={true}
              className={errors.length ? "is-invalid" : ""}
            />
          </Form.Group>
          <Form.Group as={Col} sm={2} className="mr-2">
            <Form.Control
              aria-label="test"
              type="text"
              name="test"
              onChange={(e) => onUnsavedChange(e)}
              value={unsavedOption.test || ""}
              required={true}
              className={errors.length ? "is-invalid" : ""}
            />
          </Form.Group>
          <Form.Group as={Col} sm={4} className="mr-2">
            <Button
              className="ml-4"
              onClick={() => onSaveOption()}
              disabled={saveDisabled}>
              Save
            </Button>
            <Button
              data-testid="delete-option"
              className="ml-4"
              variant="transparent"
              onClick={() => setUnsavedOption(null)}>
              <BsXLg />
            </Button>
          </Form.Group>
          <Col className="col-sm-3 mr-2" />
          <Form.Control.Feedback type="invalid">{errors}</Form.Control.Feedback>
        </Row>
      )}
      <Row>
        <Form.Group as={Col}>
          <Button
            variant="outline-primary"
            onClick={onAddUnsaved}
            disabled={!!unsavedOption}>
            <BsPlusLg />
            Add more options
          </Button>
          <span className="ml-4">
            Units/test must be saved to apply in criteria options below.
          </span>
        </Form.Group>
      </Row>
    </div>
  );
};

export default RangeOptions;
