import React, { useContext } from "react";
import { Col, Form } from "react-bootstrap";
import { GeneContext } from "../../../contexts/GeneContext";
import { GeneNumericInput } from "../common/NumericInput";
import GenePaneHeader from "../common/GenePaneHeader";
import GenePaneRow from "../common/GenePaneRow";

const Bs2Rows = () => {
  const {
    changeSingleGeneValue,
    criteriaRankings,
    errorState,
    isCriteriaApplied,
    resetFieldsToDefaults,
    gene,
  } = useContext(GeneContext);

  const convertBooleanValue = (value: boolean | null) => {
    if (value === null) return "";
    return value.toString();
  };

  const criteria = criteriaRankings.bs2.ranking;

  return (
    <>
      <GenePaneHeader
        headers={[
          "Criteria",
          "Minimum number of healthy mutants",
          "Minimum Age (optional)",
          "Require confirmation of unaffected status",
        ]}
        onResetDefaults={() => resetFieldsToDefaults(criteria)}
        size={2}
      />

      {criteria.map((c) => {
        const minMutants = `${c}_min_healthy_mutants`;
        const minAge = `${c}_min_age`;
        const statusConf = `${c}_require_confirmation_of_unaffected_status`;
        const applied = isCriteriaApplied(c);

        return (
          <GenePaneRow
            key={`${c}-row`}
            criteria={c}
            applied={applied}
            required={[minMutants, statusConf]}>
            <GeneNumericInput
              field={minMutants}
              applied={applied}
              required={true}
              min={1}
              step={1}
              size={2}
            />

            <GeneNumericInput
              field={minAge}
              applied={applied}
              required={false}
              min={0}
              step={1}
              size={2}
            />

            <Form.Group as={Col} sm={2}>
              <Form.Control
                aria-label={statusConf}
                as="select"
                name={statusConf}
                onChange={changeSingleGeneValue}
                value={convertBooleanValue(gene[statusConf]) || ""}
                disabled={!applied}
                required={applied}
                className={errorState[statusConf] ? "is-invalid" : ""}>
                <option disabled hidden value=""></option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errorState[statusConf]}
              </Form.Control.Feedback>
            </Form.Group>
          </GenePaneRow>
        );
      })}
    </>
  );
};

export default Bs2Rows;
