import React from "react";
import RangeOptions from "./functional/RangeOptions";
import Ranges from "./functional/Ranges";

const FunctionalPane = () => {
  return (
    <>
      <div className="criteria-section first-criteria-section">
        <RangeOptions />
      </div>
      <div className="criteria-section">
        <Ranges />
      </div>
    </>
  );
};

export default FunctionalPane;
