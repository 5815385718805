import React from "react";
import { Col, Form } from "react-bootstrap";
import Select, { components, ValueContainerProps } from "react-select";
import {
  Range,
  RangeModelOptions,
  createSelectClassNames,
} from "../../../lib/Gene";

interface Selection {
  value: string;
}

interface ModelSelectProps {
  options: any;
  index: number;
  range: Range;
  updateRange: (range: Range, index: number) => void;
}

const createCustomValueContainer = (placeholder: string) => {
  return ({ children, ...props }: ValueContainerProps) => (
    <components.ValueContainer {...props}>
      <div className="custom-placeholder">{placeholder}</div>
      {React.Children.map(children, (child) => child)}
    </components.ValueContainer>
  );
};

const ModelSelect = (props: ModelSelectProps) => {
  const { index, options, range, updateRange } = props;

  const modelDescription = (model: string) => options[model].description;

  const cellDescription = (model: string, cell: string) =>
    cellTypes(model)[cell].description;

  const createOption = (value: string, label: string) => ({
    value,
    label,
  });

  const modelOption = (model: string) =>
    createOption(model, modelDescription(model));

  const cellOption = (model: string, cell: string) =>
    createOption(cell, cellDescription(model, cell));

  const modelOptions = () =>
    Object.keys(options).map((model) => modelOption(model));

  const cellTypes = (model: string) => options[model].cell_type;

  const cellOptions = (model: string) =>
    Object.keys(cellTypes(model)).map((cell) => cellOption(model, cell));

  const enableModelSelect = () => {
    const rangeOption = range.range_option;
    return (
      (range.start != null || range.stop != null) &&
      !!rangeOption.units &&
      !!rangeOption.test
    );
  };

  const hasSingleCellType = (model: string) =>
    Object.keys(cellTypes(model)).length == 1;

  const selectedModels = () =>
    Object.keys(range.model_options || []).map((model) => modelOption(model));

  const setModelOptions = (range: Range, options: RangeModelOptions) => {
    if (Object.keys(options).length) {
      range.model_options = { ...options };
    } else {
      delete range.model_options;
    }

    return range;
  };

  const onModelSelect = (selection: Selection[]) => {
    const newOptions = range.model_options || {};
    const selected = selection.map((s) => s.value);
    const current = Object.keys(newOptions);

    current.concat(selected).forEach((model) => {
      if (!current.includes(model)) {
        const value = hasSingleCellType(model)
          ? Object.keys(cellTypes(model))
          : [];
        newOptions[model] = value;
      } else if (!selected.includes(model)) {
        delete newOptions[model];
      }
    });

    updateRange(setModelOptions(range, newOptions), index);
  };

  const selectedCells = (model: string) => {
    if (!range.model_options) return [];
    return range.model_options[model].map((cell) => cellOption(model, cell));
  };

  const onCellTypeChange = (key: string, selection: Selection[]) => {
    const values = selection.map((el: any) => el.value);
    range.model_options![key] = [...values];
    updateRange(range, index);
  };

  const renderCellTypePlaceholder = () =>
    !range.model_options || !Object.keys(range.model_options).length;

  const isErrored = (model: string) => {
    const options = range.model_options ? range.model_options[model] : [];
    return !options.length;
  };

  return (
    <>
      <Form.Group as={Col} sm={2} className="range-fields mb-3">
        <Select
          aria-label="model-select"
          data-testid="model-select"
          classNames={createSelectClassNames()}
          value={selectedModels()}
          onChange={onModelSelect}
          options={modelOptions()}
          placeholder="Select a model"
          closeMenuOnSelect
          isClearable={false}
          isMulti
          isDisabled={!enableModelSelect()}
        />
      </Form.Group>
      <Form.Group as={Col} sm={2} className="range-fields">
        {range.model_options &&
          Object.keys(range.model_options).map((model) => {
            const errored = isErrored(model);
            const inputClass = errored ? "" : "mb-3";
            const errorClass = errored ? "mb-3" : "";
            const container = createCustomValueContainer(
              options[model].description
            );
            return (
              <React.Fragment key={model}>
                <Select
                  aria-label={`${model}-select`}
                  className={inputClass}
                  classNames={createSelectClassNames()}
                  components={{ ValueContainer: container }}
                  value={selectedCells(model)}
                  onChange={(selection: Selection[]) =>
                    onCellTypeChange(model, selection)
                  }
                  options={cellOptions(model)}
                  placeholder=""
                  closeMenuOnSelect
                  isClearable={false}
                  isMulti
                />
                {isErrored(model) && (
                  <Form.Control.Feedback type="invalid" className={errorClass}>
                    Required
                  </Form.Control.Feedback>
                )}
              </React.Fragment>
            );
          })}
        {renderCellTypePlaceholder() && (
          <Select
            aria-label="placeholder-select"
            placeholder="Cell type"
            isMulti
            isDisabled
          />
        )}
      </Form.Group>
    </>
  );
};

export default ModelSelect;
