import { ErrorState } from "../components/GenePanes";
import { GeneContextType } from "../contexts/GeneContext";
import { CriteriaRanking, GermlineDefaults } from "./Gene";

export const REQUIRED = "Required.";
export const PS4_ERROR =
  "At least one non-truncating and truncating criteria must be applied.";
const SEPARATE_PS4 = "separate_ps4_settings";

export default class GeneFormValidator {
  static validateFormEvent(
    e: React.ChangeEvent<any>,
    context: GeneContextType
  ) {
    let { name, value, required } = e.target;
    let errors: ErrorState = {};

    value = this.resolveValueWithDefaults(
      name,
      value,
      context.germlineDefaults
    );

    const result =
      [
        this.validateRequired(value, required),
        this.validateMin(e),
        this.validateMax(e),
      ].find((r) => r) || null;

    errors[name] = result;

    if (this.shouldValidatePs4(name, value, context)) {
      context.gene[name] = value;
      const result = this.validatePs4Application(context);
      errors[SEPARATE_PS4] = result;
    }

    if (this.fieldHasAssociatedRankings(name, context)) {
      context.gene[name] = value;
      const rankedErrors = this.validateRankedFields(name, context);
      for (const [key, value] of Object.entries(rankedErrors)) {
        if (!errors[key]) errors[key] = value;
      }
    }

    return errors;
  }

  static validateToggledApply(
    criteria: string,
    required: boolean,
    requiredField: string[],
    context: GeneContextType
  ) {
    context.gene[`apply_${criteria}`] = required;
    const errors = this.validateRankedFields(criteria, context);

    requiredField.forEach((r) => {
      const value = this.resolveValueWithDefaults(
        r,
        context.gene[r],
        context.germlineDefaults
      );
      const error = this.validateRequired(value, required);
      if (
        !errors[r] &&
        (!context.errorState[r] || context.errorState[r] === REQUIRED)
      ) {
        errors[r] = error;
      }
    });

    if (this.shouldValidatePs4(`apply_${criteria}`, required, context)) {
      const result = this.validatePs4Application(context);
      errors[SEPARATE_PS4] = result;
    }

    return errors;
  }

  static validateRankedFields(name: string, context: GeneContextType) {
    const { gene, criteriaRankings, germlineDefaults } = context;
    const errors: ErrorState = {};
    const lookup = this.findRankingLookupForField(name, context);
    if (!lookup) return errors;

    const rankings = criteriaRankings[lookup];
    const rankedFieldNames = this.getNestedMapOfRankedFieldNames(
      name,
      rankings
    );

    rankedFieldNames.forEach((fieldNames) => {
      const rankedFields = this.getNonNullAppliedFieldNames(
        fieldNames,
        rankings,
        context,
        germlineDefaults
      );
      const values = rankedFields.map((field) => {
        return gene[field] !== null ? gene[field] : germlineDefaults[field];
      });

      // Reset errors for unappliedFields in the ranking
      const unappliedField = fieldNames.filter(
        (field) => !rankedFields.includes(field)
      );
      unappliedField.forEach((field) => {
        const error = context.errorState[field];
        if (!!error && error !== REQUIRED) {
          errors[field] = null;
        }
      });

      rankedFields.forEach((field, index) => {
        const fieldErrors = [];

        if (
          index !== 0 &&
          parseFloat(values[index]) > parseFloat(values[index - 1])
        ) {
          fieldErrors.push(this.lessThanErrorMessage(values[index - 1]));
        }

        if (
          index !== rankedFields.length - 1 &&
          parseFloat(values[index]) < parseFloat(values[index + 1])
        ) {
          fieldErrors.push(this.greaterThanErrorMessage(values[index + 1]));
        }

        errors[field] = fieldErrors.length ? fieldErrors.join(" ") : null;
      });
    });

    return errors;
  }

  static greaterThanErrorMessage(min: string | number) {
    return `Must be greater than or equal to ${min}.`;
  }

  static lessThanErrorMessage(max: string | number) {
    return `Must be less than or equal to ${max}.`;
  }

  static resolveValueWithDefaults(
    field: string,
    value: any,
    defaults: GermlineDefaults
  ) {
    return value !== null && value !== "" ? value : defaults[field] || null;
  }

  static validateRequired(value: any, required: boolean) {
    return required && (value === null || value === "") ? REQUIRED : null;
  }

  static valueIsEmptyOrNaN(value: any) {
    return value === null || value === "" || isNaN(Number(value));
  }

  static validateMin(e: React.ChangeEvent<any>) {
    const { min, value } = e.target;
    if (this.valueIsEmptyOrNaN(value) || min === undefined) return null;
    return !!min && Number(value) < Number(min)
      ? this.greaterThanErrorMessage(min)
      : null;
  }

  static validateMax(e: React.ChangeEvent<any>) {
    const { max, value } = e.target;
    if (this.valueIsEmptyOrNaN(value) || max === undefined) return null;
    return !!max && Number(value) > Number(max)
      ? this.lessThanErrorMessage(max)
      : null;
  }

  static findRankingLookupForField(name: string, context: GeneContextType) {
    const { criteriaRankings } = context;
    const fields = Object.entries(criteriaRankings).reduce(
      (acc: Record<string, string>, [key, value]) => {
        value.ranking.forEach((r: string) => {
          acc[`${r}`] = key; // Add category to match by just category

          value.fields.forEach((f: string) => {
            acc[`${r}_${f}`] = key; // Add full field name to match field name
          });
        });
        return acc;
      },
      {}
    );

    return fields[name];
  }

  static fieldHasAssociatedRankings(name: string, context: GeneContextType) {
    return !!this.findRankingLookupForField(name, context);
  }

  static findFieldMatchForRankings(name: string, rankings: CriteriaRanking) {
    const fields = rankings ? rankings.fields : [];
    return fields.find((field) => name.includes(field));
  }

  static getLookupForApplied(name: string, rankings: CriteriaRanking) {
    const match = this.findFieldMatchForRankings(name, rankings);
    return !!match ? name.replace(`_${match}`, "") : name;
  }

  static getNestedMapOfRankedFieldNames(
    name: string,
    rankings: CriteriaRanking
  ) {
    const match = this.findFieldMatchForRankings(name, rankings);
    if (match)
      return [rankings.ranking.map((ranking) => `${ranking}_${match}`)];
    return rankings.fields.map((field) => {
      return rankings.ranking.map((ranking) => `${ranking}_${field}`);
    });
  }

  static getAssociatedCriteria(name: string, rankings: CriteriaRanking) {
    return rankings.ranking
      .filter((ranking) => name.includes(ranking))
      .sort((a, b) => b.length - a.length)
      .find(Boolean);
  }

  static getNonNullAppliedFieldNames(
    fieldNames: string[],
    rankings: CriteriaRanking,
    context: GeneContextType,
    defaults: GermlineDefaults
  ) {
    const { gene } = context;
    return fieldNames
      .filter((field) => gene[field] !== null || defaults[field])
      .filter((field) => {
        const criteria = this.getAssociatedCriteria(field, rankings);
        if (!criteria) return true;
        const apply = `apply_${criteria}`;
        return gene[apply] !== null ? gene[apply] : !!defaults[apply];
      });
  }

  static shouldValidatePs4(name: string, value: any, context: GeneContextType) {
    const { gene } = context;
    if (name !== SEPARATE_PS4 && !gene.separate_ps4_settings) return false;
    return (
      (name === SEPARATE_PS4 && (value === "true" || value === true)) ||
      name.startsWith("apply_ps4")
    );
  }

  static validatePs4Application(context: GeneContextType) {
    const { criteriaRankings, gene, germlineDefaults } = context;
    const rankings = [
      criteriaRankings.ps4.ranking,
      criteriaRankings.ps4_truncating.ranking,
    ];
    const valid = rankings.reduce(
      (acc, criteria: string[]) =>
        acc &&
        criteria.some((c) => {
          const apply = `apply_${c}`;
          return gene[apply] !== null ? gene[apply] : !!germlineDefaults[apply];
        }),
      true
    );
    return valid ? null : PS4_ERROR;
  }
}
