import React, { ReactChild, useContext } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { formatCriteriaTitle } from "../../../lib/Gene";
import { GeneContext } from "../../../contexts/GeneContext";
interface GenePaneRowProps {
  applied: boolean;
  children?: ReactChild | ReactChild[];
  criteria: string;
  required: string[];
}

const GenePaneRow = (props: GenePaneRowProps) => {
  const { applied, children, criteria, required } = props;
  const { toggleAppliedState } = useContext(GeneContext);

  return (
    <Row>
      <Col className="apply-criteria">
        <Form.Check
          aria-label={criteria}
          className="pane-check"
          type="checkbox"
          checked={applied}
          onChange={(e) => {
            toggleAppliedState(criteria, e.target.checked, required);
          }}
        />
      </Col>
      <Col data-testid="title" className="col-sm-2 font-weight-bold">
        {formatCriteriaTitle(criteria)}
      </Col>
      {React.Children.map(children, (child, i) => {
        return <React.Fragment key={i}>{child}</React.Fragment>;
      })}
    </Row>
  );
};

export default GenePaneRow;
