import { CategoryOptions } from "./CategoryOptions";
import { ModelOptions } from "./ModelOptions";

export interface ArticleCounts {
  gene: { count: number };
}

export interface CriteriaRanking {
  ranking: string[];
  fields: string[];
}

export interface CriteriaRankings extends Record<string, any> {
  ba1: CriteriaRanking;
  ps4: CriteriaRanking;
  ps4_truncating: CriteriaRanking;
  pp1: CriteriaRanking;
  bs4: CriteriaRanking;
  ps2: CriteriaRanking;
  pm6: CriteriaRanking;
  pm3: CriteriaRanking;
  bs2: CriteriaRanking;
  bp2: CriteriaRanking;
  bp5: CriteriaRanking;
  pm5: CriteriaRanking;
  ps3: CriteriaRanking;
}

export interface Pvs1Categories extends Record<string, Pvs1Category[]> {
  "": Pvs1Category[];
  strong: Pvs1Category[];
  moderate: Pvs1Category[];
  supporting: Pvs1Category[];
}

export interface Gene extends PartialGene {
  pvs1_categories: Pvs1Categories | null;
  ranges: Range[] | null;
  range_options: RangeOption[] | null;
}

export interface PartialGene {
  [key: string]: any;
}

export interface Pvs1Category {
  type: string;
  degree: string;
}

export interface Domain extends Record<string, any> {
  id?: number;
  name: string | null;
  start: number | null;
  end: number | null;
  ignore: boolean;
  application: string | null;
  strength: string | null;
  source: string;
}
export interface RangeOption extends Record<string, string> {
  test: string;
  units: string;
}

export interface RangeModelOptions {
  [key: string]: string[];
}

export interface Range extends Record<string, any> {
  start: number | null;
  stop: number | null;
  criteria: string;
  range_option: RangeOption;
  model_options?: RangeModelOptions;
}

export interface GermlineDefaults {
  [key: string]: any;
}

export interface Option {
  value: string;
  description: string;
}

export interface DomainOptions extends Record<string, Option[]> {
  application: Option[];
  strength: Option[];
}

export interface StrengthOption {
  description: string;
  options: Option[];
}

export interface StrengthOptions extends Record<string, StrengthOption> {
  pp2_strength: StrengthOption;
  bp1_strength: StrengthOption;
  bp7_strength: StrengthOption;
  hotspot_strength: StrengthOption;
}

export interface StaticData {
  category_options: CategoryOptions;
  criteria_rankings: CriteriaRankings;
  domain_defaults: GermlineDefaults;
  domain_options: DomainOptions;
  germline_defaults: GermlineDefaults;
  model_options: ModelOptions;
  strength_options: StrengthOptions;
}

export const formatCriteriaTitle = (field: string) => {
  let [first, ...rest] = field.split("_");
  const excludedEndings = ["truncating"];

  if (/\d/.test(first)) {
    if (rest.length && excludedEndings.includes(rest[rest.length - 1]))
      rest.pop();
    const useHyphen = rest.length && rest[0] != "strength";
    const altPostFix = rest[0] === "strength" ? " " : "";
    first = `${first.toUpperCase()}${useHyphen ? " - " : altPostFix}`;
    return first + rest.join(" ");
  }

  if (first === "lof") {
    first = first.toUpperCase();
  } else {
    first = first.charAt(0).toUpperCase() + first.slice(1);
  }

  return [first].concat(rest).join(" ");
};

export const createSelectClassNames = (isPlaceholder: boolean = false) => {
  const secondaryClass = isPlaceholder ? "default" : "";
  return {
    control: () => "control",
    multiValue: () => `multi-value ${secondaryClass}`,
    multiValueLabel: () => `multi-value-label ${secondaryClass}`,
    multiValueRemove: () =>
      `multi-value-remove ${isPlaceholder ? "default-remove" : ""}`,
  };
};
