import { ErrorState } from "../components/GenePanes";
import { Domain, Gene } from "./Gene";

const nullOutRelatedApplyCriteriaFields = (
  key: string,
  band: string[],
  gene: Gene
) => {
  if (band.some((c) => key === `apply_${c}`)) gene[key] = null;
  return gene;
};

const nullOutRelatedCriteriaFields = (
  key: string,
  criteria: string[],
  gene: Gene
) => {
  if (criteria.some((c) => key.startsWith(c))) gene[key] = null;
  return gene;
};

const nullOutRelatedRankedCriteriaErrors = (
  key: string,
  criteria: string[],
  errors: ErrorState
) => {
  if (criteria.some((c) => key.startsWith(c))) errors[key] = null;
  return errors;
};

const nullOutDomainErrors = (criteria: string[], errors: ErrorState) => {
  if (criteria.includes("domains")) errors["gene_features"] = null;
  return errors;
};

export const resetGeneForCriteria = (criteria: string[], gene: Gene): Gene => {
  let modGene = { ...gene };

  for (const [key, _] of Object.entries(modGene)) {
    modGene = {
      ...modGene,
      ...nullOutRelatedApplyCriteriaFields(key, criteria, modGene),
      ...nullOutRelatedCriteriaFields(key, criteria, modGene),
    };
  }

  return modGene;
};

export const nullOutErrorsForCriteria = (
  criteria: string[],
  gene: Gene
): ErrorState => {
  let errors: ErrorState = {};

  for (const [key, _] of Object.entries(gene)) {
    errors = {
      ...errors,
      ...nullOutRelatedRankedCriteriaErrors(key, criteria, errors),
      ...nullOutDomainErrors(criteria, errors),
    };
  }

  return errors;
};

export const nullOutDomainFields = (criteria: string[], domains: Domain[]) => {
  let modDomains = [...domains];

  if (criteria.includes("domains")) {
    modDomains = domains.map((d) => {
      d.application = null;
      d.strength = null;
      return d;
    });
  }

  return modDomains;
};
