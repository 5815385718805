import React, { useContext } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { formatCriteriaTitle } from "../../lib/Gene";
import { GeneContext } from "../../contexts/GeneContext";
import GenePaneHeader from "./common/GenePaneHeader";
import Domains from "./intrinsic/Domains";
import Pm1Rows from "./intrinsic/Pm1Rows";
import Pm5Rows from "./intrinsic/Pm5Rows";
import Pvs1Rows from "./intrinsic/Pvs1Rows";
import GeneSelect from "./common/GeneSelect";
import Alert from "../common/Alert";

const IntrinsicPane = () => {
  const {
    changeSingleGeneValue,
    errorState,
    gene,
    germlineDefaults,
    resetFieldsToDefaults,
    strengthOptions,
  } = useContext(GeneContext);

  const convertBooleanValue = (value: boolean | null) => {
    if (value === null) return "";
    return value.toString();
  };

  const onBaseReset = () => {
    const criteria = [
      "lof_pathogenic",
      "pvs1",
      "low_rate_benign_missense",
      "missense_common_mechanism",
      "only_truncating_pathogenic",
      "pp2_strength",
      "bp1_strength",
      "bp7_strength",
      "disable_pp3",
      "disable_bp4",
      "disable_pm4",
    ];
    resetFieldsToDefaults(criteria);
  };

  const resolvedValue = (field: string) => {
    return gene[field] !== null ? gene[field] : germlineDefaults[field];
  };

  const renderPvs1 = () => {
    return resolvedValue("lof_pathogenic");
  };

  const renderPp2 = () => {
    let lowRate = resolvedValue("low_rate_benign_missense");
    let commonMech = resolvedValue("missense_common_mechanism");
    return lowRate && commonMech;
  };

  const renderBp1 = () => {
    return resolvedValue("only_truncating_pathogenic");
  };

  const renderQuestionRow = (field: string) => {
    return (
      <>
        <Col className="font-weight-bold col-sm-2 mr-2">
          {formatCriteriaTitle(field)}
        </Col>
        <Form.Group as={Col} sm={4} className="mr-2">
          <Form.Control
            aria-label={field}
            as="select"
            name={field}
            onChange={(e) => changeSingleGeneValue(e)}
            value={convertBooleanValue(gene[field]) || germlineDefaults[field]}>
            <option value="true">True</option>
            <option value="false">False</option>
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errorState[field]}
          </Form.Control.Feedback>
        </Form.Group>
        <Alert />
      </>
    );
  };

  return (
    <>
      <div className="first-criteria-section criteria-section">
        <GenePaneHeader includeApply={false} onResetDefaults={onBaseReset} />

        <Row>
          {renderQuestionRow("lof_pathogenic")}
          {renderPvs1() && <Pvs1Rows />}
        </Row>

        <Row>
          {renderQuestionRow("low_rate_benign_missense")}
          <div className="w-100"></div>
          {renderQuestionRow("missense_common_mechanism")}
          <div className="w-100"></div>

          {renderPp2() && <GeneSelect field="pp2_strength" />}
        </Row>

        <Row>
          {renderQuestionRow("only_truncating_pathogenic")}
          <div className="w-100"></div>
          {renderBp1() && <GeneSelect field="bp1_strength" />}
        </Row>

        <Row>
          <GeneSelect field="bp7_strength" />
        </Row>

        {["pp3", "bp4", "pm4"].map((category) => {
          const field = `disable_${category}`;
          return (
            <Row key={field}>
              <Col>
                <Form.Check
                  aria-label={field}
                  name={field}
                  className="display-inline mr-2"
                  label={`Disable ${category.toUpperCase()}`}
                  type="checkbox"
                  checked={!!gene[field]}
                  onChange={(e) => {
                    changeSingleGeneValue(e);
                  }}
                />
              </Col>
            </Row>
          );
        })}
      </div>

      <div className="criteria-section">
        <Domains />
      </div>

      <div className="criteria-section">
        <Pm1Rows />
      </div>

      <div className="criteria-section">
        <Pm5Rows />
      </div>
    </>
  );
};

export default IntrinsicPane;
