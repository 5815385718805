import React, { useContext } from "react";
import { GeneContext } from "../../../contexts/GeneContext";
import { GeneNumericInput } from "../common/NumericInput";
import GenePaneHeader from "../common/GenePaneHeader";
import GenePaneRow from "../common/GenePaneRow";

const Pp1Rows = () => {
  const { criteriaRankings, isCriteriaApplied, resetFieldsToDefaults } =
    useContext(GeneContext);

  const criteria = criteriaRankings.pp1.ranking;

  return (
    <>
      <GenePaneHeader
        headers={[
          "Criteria",
          "Minimum Meioses",
          "MAF (optional)",
          "Minimum Families (optional)",
        ]}
        onResetDefaults={() => resetFieldsToDefaults(criteria)}
        size={2}
      />

      {criteria.map((c) => {
        const minMeioses = `${c}_min_meioses`;
        const maf = `${c}_maf`;
        const minFamilies = `${c}_min_families`;
        const applied = isCriteriaApplied(c);

        return (
          <GenePaneRow
            key={`${c}-row`}
            criteria={c}
            applied={applied}
            required={[minMeioses]}>
            <GeneNumericInput
              field={minMeioses}
              applied={applied}
              required={true}
              min={1}
              step={1}
              size={2}
            />
            <GeneNumericInput
              allowDecimals={true}
              field={maf}
              applied={applied}
              min={0}
              max={1}
              step={0.0001}
              size={2}
            />
            <GeneNumericInput
              field={minFamilies}
              applied={applied}
              required={false}
              min={1}
              step={1}
              size={2}
            />
          </GenePaneRow>
        );
      })}
    </>
  );
};

export default Pp1Rows;
