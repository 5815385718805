import React, { useContext } from "react";
import { GeneContext } from "../../contexts/GeneContext";
import GenePaneRow from "./common/GenePaneRow";
import GenePaneHeader from "./common/GenePaneHeader";
import { GeneNumericInput } from "./common/NumericInput";
import Alert from "../common/Alert";

const WARNING = "WARNING: This will affect main BA1, BS1, and PM2 thresholds.";

const PopulationPane = () => {
  const { criteriaRankings, isCriteriaApplied, resetFieldsToDefaults } =
    useContext(GeneContext);

  const onResetDefaults = () => {
    const criteria = criteriaRankings.ba1.ranking;
    resetFieldsToDefaults(criteria, WARNING);
  };

  const acmgCriteria = ["ba1_threshold", "bs1_threshold", "pm2_threshold"];

  const renderRows = () => {
    const criteria = criteriaRankings.ba1.ranking;

    return criteria.map((c) => {
      const threshold = `${c}_threshold`;
      const alleleCount = `${c}_min_allele_count`;
      const criteriaApplied = isCriteriaApplied(c);

      return (
        <GenePaneRow
          key={`${c}-row`}
          criteria={c}
          applied={criteriaApplied}
          required={[threshold]}>
          <GeneNumericInput
            allowDecimals={true}
            applied={criteriaApplied}
            field={threshold}
            required={criteriaApplied}
            min={0}
            max={1}
            step={0.0001}
          />
          {!c.includes("pm2") ? (
            <GeneNumericInput
              applied={criteriaApplied}
              field={alleleCount}
              min={1}
              step={1}
            />
          ) : (
            <></>
          )}
          {["ba1", "bs1", "pm2"].includes(c) ? <Alert /> : <></>}
        </GenePaneRow>
      );
    });
  };

  return (
    <div className="first-criteria-section criteria-section">
      <GenePaneHeader
        headers={["Criteria", "Boundary", "Minimum Allele Count (optional)"]}
        onResetDefaults={onResetDefaults}
      />
      {renderRows()}
    </div>
  );
};

export default PopulationPane;
