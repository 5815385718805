import React, { useContext } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import cloneDeep from "lodash/cloneDeep";
import { GeneContext } from "../../../contexts/GeneContext";
import {
  Pvs1Category,
  Pvs1Categories,
  createSelectClassNames,
  formatCriteriaTitle,
} from "../../../lib/Gene";
import { GeneNumericInput } from "../common/NumericInput";

interface Selection {
  degree: string;
  type: string;
  description: string;
}

const Pvs1Rows = () => {
  const {
    categoryOptions,
    changeStructuredGeneValue,
    errorState,
    germlineDefaults,
    gene,
  } = useContext(GeneContext);

  const pvs1Criteria = [
    "pvs1",
    "pvs1_strong",
    "pvs1_moderate",
    "pvs1_supporting",
  ];

  const isUsingDefaultCategories = () => !gene["pvs1_categories"];

  const resolveCategories = (strength: string): Pvs1Category[] => {
    if (gene.pvs1_categories !== null && gene.pvs1_categories[strength]) {
      return cloneDeep(gene.pvs1_categories)[strength];
    }

    return cloneDeep(germlineDefaults).pvs1_categories[strength];
  };

  const resolveValue = (field: string) => {
    return gene[field] == null ? germlineDefaults[field] : gene[field];
  };

  const formatOptions = () => {
    const splicing = categoryOptions.splicing;
    const options = [];
    for (const [dKey, dValue] of Object.entries(splicing.impact_degrees)) {
      for (const [tKey, tValue] of Object.entries(splicing.impact_types)) {
        if (!tValue.only || tValue.only.includes(dKey)) {
          const description = `${dValue.description} - ${tValue.description}`;
          options.push({ degree: dKey, type: tKey, description });
        }
      }
    }

    return options;
  };

  const options = formatOptions();

  const getStrength = (criteria: string) => criteria.replace(/pvs1_?/, "");

  const hasEqualNonNullMinEvents = (criteria: string, comparison: string) => {
    const minEvents = resolveValue(`${criteria}_minimum_events`);
    const compMinEvents = resolveValue(`${comparison}_minimum_events`);
    return (
      !!minEvents &&
      !!compMinEvents &&
      Number(minEvents) === Number(compMinEvents)
    );
  };

  const filterAvailableOptions = (criteria: string) => {
    const exclude = pvs1Criteria
      .filter((c) => c !== criteria && hasEqualNonNullMinEvents(criteria, c))
      .flatMap((c) => resolveCategories(getStrength(c)))
      .map((c) => `${c.degree}-${c.type}`);

    return options.filter((o) => !exclude.includes(`${o.degree}-${o.type}`));
  };

  const selectedValues = (criteria: string) => {
    const strength = getStrength(criteria);
    const categories = resolveCategories(strength).map(
      (c) => `${c.degree}-${c.type}`
    );
    return options.filter((o) => categories.includes(`${o.degree}-${o.type}`));
  };

  const updatePvs1Categories = (criteria: string, selected: Selection[]) => {
    const strength = getStrength(criteria);
    const allCategories: Pvs1Categories =
      cloneDeep(gene.pvs1_categories) ||
      cloneDeep(germlineDefaults.pvs1_categories);
    allCategories[strength] = selected.map((s) => ({
      degree: s.degree,
      type: s.type,
    }));
    const isEmpty = Object.entries(allCategories).every(
      ([_, value]) => !value.length
    );
    changeStructuredGeneValue(
      isEmpty ? null : allCategories,
      "pvs1_categories"
    );
  };

  return (
    <Row>
      <div className="w-100"></div>
      <Col className="col-sm-2"></Col>
      <Col className="font-weight-bold col-sm-4">Minimum events</Col>
      <Col className="font-weight-bold col-sm-4">Category options</Col>
      {pvs1Criteria.map((c, i) => {
        const minEvents = `${c}_minimum_events`;
        const last = i === pvs1Criteria.length - 1;
        return (
          <React.Fragment key={c}>
            <div className="w-100"></div>
            <Col className="font-weight-bold col-sm-2">
              {formatCriteriaTitle(c)}
            </Col>
            <GeneNumericInput field={minEvents} min={1} size={4} />
            <Form.Group as={Col} sm={4} className="category-select mr-2">
              <Select
                classNames={createSelectClassNames(isUsingDefaultCategories())}
                value={selectedValues(c)}
                onChange={(selected: Selection[]) =>
                  updatePvs1Categories(c, selected)
                }
                options={filterAvailableOptions(c)}
                getOptionLabel={(option) => option.description}
                getOptionValue={(option) => option.description}
                placeholder="Select categories"
                closeMenuOnSelect
                isClearable={false}
                isMulti
                isDisabled={!gene[minEvents] && !germlineDefaults[minEvents]}
              />
              {last && (
                <Form.Control.Feedback type="invalid">
                  {errorState["pvs1_categories"]}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </React.Fragment>
        );
      })}
    </Row>
  );
};

export default Pvs1Rows;
