import React, { useContext } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { GeneContext } from "../../../contexts/GeneContext";
import { GeneNumericInput } from "../common/NumericInput";
import GenePaneHeader from "../common/GenePaneHeader";
import GenePaneRow from "../common/GenePaneRow";

const Ps4Rows = () => {
  const {
    changeSingleGeneValue,
    criteriaRankings,
    errorState,
    gene,
    isCriteriaApplied,
    resetFieldsToDefaults,
  } = useContext(GeneContext);

  const criteria = criteriaRankings.ps4.ranking;
  const truncatingCriteria = criteriaRankings.ps4_truncating.ranking;

  const createPs4Rows = (isTruncating: boolean = false) => {
    const resolvedCriteria = isTruncating ? truncatingCriteria : criteria;
    return (
      <>
        <Row>
          <span className="font-weight-bold mt-2">
            {isTruncating
              ? "Truncating"
              : gene.separate_ps4_settings
              ? "Non-truncating"
              : "Truncating/non-truncating"}
          </span>
        </Row>
        {resolvedCriteria.map((c) => {
          const minProbands = `${c}_min_probands`;
          const maf = `${c}_maf`;
          const applied = isCriteriaApplied(c);

          return (
            <GenePaneRow
              key={`${c}-row`}
              criteria={c}
              applied={applied}
              required={[minProbands]}>
              <GeneNumericInput
                field={minProbands}
                applied={applied}
                required={true}
                min={1}
                step={1}
              />
              <GeneNumericInput
                allowDecimals={true}
                field={maf}
                applied={applied}
                min={0}
                max={1}
                step={0.0001}
              />
            </GenePaneRow>
          );
        })}
      </>
    );
  };

  return (
    <>
      <Row>
        <Col>
          <Form.Check
            aria-label="separate_ps4_settings"
            name="separate_ps4_settings"
            className="display-inline"
            label="Separate PS4 settings for truncating and non-truncating variants"
            type="checkbox"
            checked={!!gene.separate_ps4_settings}
            onChange={(e) => {
              changeSingleGeneValue(e);
            }}
          />
        </Col>
      </Row>

      <GenePaneHeader
        headers={["Criteria", "Minimum Probands", "MAF (optional)"]}
        onResetDefaults={() =>
          resetFieldsToDefaults([
            "separate_ps4_settings",
            ...truncatingCriteria,
            ...criteria,
          ])
        }
      />

      {createPs4Rows()}
      {gene.separate_ps4_settings && createPs4Rows(true)}
      {errorState.separate_ps4_settings && (
        <Row>
          <Form.Control.Feedback type="invalid">
            {errorState["separate_ps4_settings"]}
          </Form.Control.Feedback>
        </Row>
      )}
    </>
  );
};

export default Ps4Rows;
