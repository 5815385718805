import React, { useContext } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { GeneContext } from "../../contexts/GeneContext";

const GENE_NAMES =
  "https://www.genenames.org/data/gene-symbol-report/#!/hgnc_id";
const NCBI = "https://www.ncbi.nlm.nih.gov/gene";

const MainPane = () => {
  const { articleCounts, changeSingleGeneValue, errorState, gene } =
    useContext(GeneContext);

  const geneLink = `/genes/${gene.symbol}`;
  const hgncIdLink = `${GENE_NAMES}/${gene.hgnc_id}`;
  const ncbiIdLink = `${NCBI}/${gene.ncbi_id}`;

  return (
    <div>
      <Row>
        <Col className="font-weight-bold">Symbol</Col>
        <Col>
          <a href={geneLink}>{gene.symbol}</a>
        </Col>
      </Row>
      <Row>
        <Col className="font-weight-bold">HGNC Id</Col>
        <Col>
          <a href={hgncIdLink} target="_blank">
            {gene.hgnc_id}
          </a>
        </Col>
      </Row>
      <Row>
        <Col className="font-weight-bold">NCBI Id</Col>
        <Col>
          <a href={ncbiIdLink} target="_blank">
            {gene.ncbi_id}
          </a>
        </Col>
      </Row>
      <Row>
        <Col className="font-weight-bold">Locus Type</Col>
        <Col>{gene.locus_type}</Col>
      </Row>
      <Row>
        <Col className="font-weight-bold">Locus Group</Col>
        <Col>{gene.locus_group}</Col>
      </Row>
      {articleCounts && (
        <Row>
          <Col className="font-weight-bold">Articles Matched</Col>
          <Col>{articleCounts.gene.count}</Col>
        </Row>
      )}
      <Row className="align-items-center">
        <Col className="font-weight-bold">Tumor Suppressor Status</Col>
        <Col>
          <Form.Control
            aria-label="tumor_suppressor_status"
            as="select"
            name="tumor_suppressor_status"
            onChange={changeSingleGeneValue}
            value={gene.tumor_suppressor_status || ""}>
            <option value=""></option>
            <option value="tumor_suppressor">Tumor Suppressor</option>
            <option value="oncogenic">Oncogenic</option>
            <option value="tumor_suppressor_oncogenic">
              Tumor Suppressor + Oncogenic
            </option>
            <option value="unknown">Unknown</option>
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errorState["tumor_suppressor_status"]}
          </Form.Control.Feedback>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col className="font-weight-bold">SBS1 threshold</Col>
        <Col>
          <Form.Control
            aria-label="sbs1_threshold"
            type="number"
            name="sbs1_threshold"
            onChange={changeSingleGeneValue}
            value={gene.sbs1_threshold || ""}
            min="0"
            max="1"
            step="0.0001"
          />
          <Form.Control.Feedback type="invalid">
            {errorState["sbs1_threshold"]}
          </Form.Control.Feedback>
        </Col>
      </Row>
    </div>
  );
};

export default MainPane;
